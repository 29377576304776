<template>
  <div @click="hideAllDropdown">
    <BreadCrumb title="Legal Advice" subtitle="Legal Argument / Result / Summary">
    </BreadCrumb>
    <div class="d-flex justify-content-between align-items-center">
      <div class="d-flex align-items-center left" @click="goBack" >
        <span class="material-symbols-rounded me-2">arrow_back</span>
        <span class="mb-0">Back</span>
      </div>
      <div class="d-flex align-items-center right" id="export">
        <span class="mb-0">Export</span>
        <span class="material-symbols-rounded me-2">
         keyboard_arrow_up
        </span>
      </div>
    </div>
    <b-tooltip
  target="export"
  triggers="click"
  placement="bottomleft"
  id="export_box"
  :custom-class="`DROPDOWN-MENU`"
>
  <div style="padding: 0px 10px;">
    <div
      v-for="(item, index) in dropdownItems"
      :key="index"
      class="dropdown-option"
      style="display: flex; align-items: center; margin-bottom: 8px;"
    >
      <!-- Replace the standard checkbox with the CheckBox component -->
      <CheckBox :set="false" :force="false" />
      <label :for="`option-${index}`" style="margin-left: 8px;">{{ item }}</label>
    </div>
    <div class="d-flex align-items-center justify-content-end ">
      <div class=" d-flex right align-items-center">
        <span class="material-symbols-rounded me-2">
          ios_share
        </span>
        <span class="mb-0">Export</span>
        </div>
      </div>

  </div>
</b-tooltip>

<div class="row">
  <div class="col-md-2 mt-5">
     <ul class="left-list">
      <li
        @click="activeSection = 'caseSummary'"
        :class="{ active: activeSection === 'caseSummary' }"
      >
        Case Summary
      </li>
      <li
        @click="activeSection = 'completeCase'"
        :class="{ active: activeSection === 'completeCase' }"
      >
        Complete Case
      </li>
      <li
        @click="activeSection = 'precedents'"
        :class="{ active: activeSection === 'precedents' }"
      >
        Precedents
      </li>
    </ul>
  </div>
  <div class="col-md-8">
    <!-- first -->
    <div v-if="activeSection === 'caseSummary'" class="main-container d-flex mt-4">
  <section class="case-summary-content-full-view">
    <header class="case-summary-header">
      <div class="case-flow-result">Smith_v_Jones_2023.pdf</div>
    </header>
  
    <article class="case-info mt-3">
      <p class="header">Case Info</p>
      <section class="card topic-card">
        <h5 class="topic-title">Topic</h5>
        <p class="topic-description">Mr. Kuang King Tan, a provisionally registered pharmacist, was convicted of raping a patient, leading the Victorian Civil and Administrative Tribunal (VCAT) to cancel his registration and impose a four-year disqualification.</p>
      </section>
      <section class="card abstract-card">
        <h5 class="abstract-title">Abstract</h5>
        <p class="abstract-description">In 2016, Mr. Kuang King Tan, a provisionally registered pharmacist, was convicted of raping a patient while working as a pharmacist intern. The Pharmacy Board of Australia referred the case to the Victorian Civil and Administrative Tribunal (VCAT), which found him guilty of professional misconduct under the Health Practitioner Regulation National Law Act 2009. As a result, Mr. Tan's registration was canceled, and he was disqualified from reapplying for four years. While the Tribunal issued orders under the Open Courts Act 2013 to protect the victim and witnesses' privacy, it denied Mr. Tan’s request for identity suppression, reinforcing public transparency and accountability in healthcare.</p>
      </section>
      <section class="card date-court-card">
        <div class="date-court-content">
          <div class="decision-date-card">
            <div class="decision-day">25</div>
            <time datetime="2016-10" class="decision-month-year">October 2016</time>
            <span class="decision-date">Decision Date</span>
          </div>
          <div class="jurisdiction-court-card">
            <div class="jurisdiction-card">
              <p class="jurisdiction-title">Jurisdiction</p>
              <h5 class="jurisdiction-location">Victoria, Australia</h5>
            </div>
            <div class="court-name-card">
              <p class="court-name-title">Court Name</p>
               <h5 class="court-name-description">Victorian Civil and Administrative Tribunal (VCAT)</h5>
            </div>
          </div>
        </div>
      </section>
    </article>
    
    <img src="https://cdn.builder.io/api/v1/image/assets/TEMP/86f8a62eda915b32cea76a2453ac2cc7d4d6e8910796cc2f1e7f7051a59c2468?placeholderIfAbsent=true&apiKey=b7b3d02748134357b1767f32084c4983" alt="" class="summary-divider" />
    
    <section class="summary">
      <header class="summary-header">
        <h3 class="summary-title">Summary</h3>
        <nav class="summary-tags">
          <button class="copy-button">
            <img src="https://cdn.builder.io/api/v1/image/assets/TEMP/4042ce02169404fc8f24e2435141f1518abb04892a209162cf916b7ff7de4ac3?placeholderIfAbsent=true&apiKey=b7b3d02748134357b1767f32084c4983" alt="Copy icon" class="img-button" />
            <span class="button-title">Copy</span>
          </button>
        </nav>
      </header>
      <article class="case-summary">
        <header class="summary-sound">
          <h5 class="brief-summary-title">Brief Summary</h5>
          <button class="export-button">
            <img src="https://cdn.builder.io/api/v1/image/assets/TEMP/be98e6e7c327af7d118fe81247fe84cb38848926818d2b97945aad22d1b8cb25?placeholderIfAbsent=true&apiKey=b7b3d02748134357b1767f32084c4983" alt="Export icon" class="img-button" />
          </button>
        </header>
        <p class="brief-summary-description">Mr. Kuang King Tan, a provisionally registered pharmacist, was convicted of raping a patient while working as a pharmacist intern. The Pharmacy Board of Australia brought the matter to the Victorian Civil and Administrative Tribunal (VCAT) in 2016. The Tribunal found Mr. Tan guilty of professional misconduct under the Health Practitioner Regulation National Law Act 2009. As a result of the misconduct, Mr. Tan's registration as a provisional pharmacist was canceled, and he was disqualified from reapplying for pharmacy registration for four years. The Tribunal made orders under the Open Courts Act 2013 to protect the privacy of the victim and witnesses, while denying Mr. Tan's request for suppression of his identity. The decision aimed to uphold transparency and accountability in healthcare professions by ensuring that the public is aware of any misconduct by practitioners. By not suppressing Mr. Tan's identity, the Tribunal also sent a message that such serious breaches of professional conduct will not be shielded from public scrutiny. Overall, the Tribunal's decision was guided by principles aimed at protecting the public, maintaining professional standards, and deterring similar misconduct in the healthcare profession. By canceling Mr. Tan's registration and imposing a four-year disqualification period, the Tribunal sought to safeguard patients from potential harm and uphold trust in the pharmacy profession. Additionally, by prioritizing transparency and accountability through its orders under the Open Courts Act 2013, the Tribunal underscored its commitment to ensuring that instances of professional misconduct are addressed openly and effectively to maintain integrity within healthcare practices.</p>
      </article>
      <section class="key-points-section">
        <h5 class="key-points-title">Key Points</h5>
        <ol class="key-points-list">
  <li class="key-point-item">
    <div class="d-flex align-item-center justify-content-between gap-3">
      <div class="text-container">
        <span class="key-point-highlight">
          Mr. Kuang King Tan, a provisionally registered pharmacist, was convicted of raping a patient while working as a pharmacist intern.
        </span>
        <p>
          This key point directly impacts the seriousness of the allegations against Mr. Tan and forms the basis of the professional misconduct case.
        </p>
      </div>
      <span class="material-symbols-rounded me-2 export-button">text_to_speech</span>
    </div>
  </li>
  <!-- Repeat similar structure for other list items -->
</ol>

      </section>
      </section>
    </section>
    </div>
    <!-- second -->
    <div v-if="activeSection === 'completeCase'" class="main-container d-flex mt-4">
    <article class="annotations-content-full-view">
      <header class="case-flow-result">Smith_v_Jones_2023.pdf</header>
      <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/b7f9256af2b023aa42c6b63aca7f520f39338d3666d1d503f6042f796275f300?placeholderIfAbsent=true&apiKey=b7b3d02748134357b1767f32084c4983" class="case-image" alt="Case document preview" />
      <footer class="case-copyright">
        NexLaw does not hold any copyright for the case content. The content within this material, including but not limited to text, images, graphics, logos, audio clips, and software, belongs to Comonlii and is protected by copyright laws. All rights are reserved by Comonlii (CommonLII is coordinated by AustLII, a joint facility of UTS and UNSW Faculties of Law, in cooperation with some of the members of the Free Access to Law Movement (FALM)). For Malaysian Cases, the copyright solely belongs to Malaysian Judiciary (e-court kehakiman).
      </footer>
    </article>
    </div>
    <!-- third -->
    <div v-if="activeSection === 'precedents'" class="main-container d-flex mt-4">
      <section class="case-summary-content-full-view">
        <h5 class="case-flow-result">Smith_v_Jones_2023.pdf</h5>
        <ul class="precedent-list">
          <div v-for="(precedent, index) in precedents" :key="index" style="border-bottom: 1px solid var(--mid-grey);">
          <li class="precedent-item" @click="toggleCaseInfo(index)">
            <span class="precedent-number">{{ index + 1 }}</span>
            <article class="precedent-card">
              <div class="precedent-content">
                <p class="precedent-title">{{ precedent.title }}</p>
                <p class="precedent-subtitle">Case Title</p>
              </div>
              <button class="expand-button"  aria-label="Expand case details">
                <img :src="precedent.expandIcon" alt="" class="expand-icon" />
              </button>
            </article>
          </li>
          
          <div v-if="precedent.showInfo" style="display: flex;justify-content: flex-end;">
            <section  class="case-info-tit">
      <header class="header">Case Info</header>
      <article class="card">
        <h5 class="topic">Topic</h5>
        <p class="content">
          Mr. Kuang King Tan, a provisionally registered pharmacist, was convicted of
          raping a patient, leading the Victorian Civil and Administrative Tribunal
          (VCAT) to cancel his registration and impose a four-year disqualification.
        </p>
      </article>
      <article class="card">
        <h5 class="abstract">Abstract</h5>
        <p class="content">
          In 2016, Mr. Kuang King Tan, a provisionally registered pharmacist, was
          convicted of raping a patient while working as a pharmacist intern. The
          Pharmacy Board of Australia referred the case to the Victorian Civil and
          Administrative Tribunal (VCAT), which found him guilty of professional
          misconduct under the Health Practitioner Regulation National Law Act 2009.
          <br /><br />
          As a result, Mr. Tan's registration was canceled, and he was disqualified
          from reapplying for four years. While the Tribunal issued orders under the
          Open Courts Act 2013 to protect the victim and witnesses' privacy, it denied
          Mr. Tan’s request for identity suppression, reinforcing public transparency
          and accountability in healthcare.
        </p>
      </article>
      <div class="card-2">
        <div class="decision-date-card">
          <div class="date-day">25</div>
          <div class="date-month-year">October 2016</div>
          <div class="decision-date">Decision Date</div>
        </div>
        <div class="jurisdiction">
          <div class="jurisdiction-card">
            <div class="jurisdiction-label">Jurisdiction</div>
            <div class="jurisdiction-value">Victoria, Australia</div>
          </div>
          <div class="court-name-card">
            <div class="court-name-label">Court Name</div>
            <div class="court-name-value">
              Victorian Civil and Administrative Tribunal (VCAT)
            </div>
          </div>
        </div>
      </div>
          </section>
          </div>
          </div>
        </ul>
      </section>
      </div>
  </div>
  <div class="col-md-2"></div>
</div>
  </div>
</template>
<script>
import CheckBox from './../../components/input/CheckBox.vue';
import BreadCrumb from '../../components/misc/BreadCrumb.vue';
export default {
  name: 'LegalMemoOpinion',
  components: {
    CheckBox,BreadCrumb
  },
  data() {
    return {
      dropdownItems: ['PDF', 'Document'],
      activeSection: 'caseSummary',
      precedents: [
        { title: 'Pharmacy Board of Australia v Tan (Review and Regulation) [2016] VCAT 1653', showInfo: false, expandIcon: 'https://cdn.builder.io/api/v1/image/assets/TEMP/cfca5652a69de8c5188c4382f916216d531c08a5dbba6987ff5b337b47157886?placeholderIfAbsent=true&apiKey=b7b3d02748134357b1767f32084c4983' },
        { title: 'Pharmacy Board of Australia v Tan (Review and Regulation) [2016] VCAT 1653', showInfo: false, expandIcon: 'https://cdn.builder.io/api/v1/image/assets/TEMP/4994a1cad7e15e6304b1d82815d2b3ef9cb515baf0e1280194b6634d7a0ac429?placeholderIfAbsent=true&apiKey=b7b3d02748134357b1767f32084c4983' },
        { title: 'Pharmacy Board of Australia v Tan (Review and Regulation) [2016] VCAT 1653', showInfo: false, expandIcon: 'https://cdn.builder.io/api/v1/image/assets/TEMP/4994a1cad7e15e6304b1d82815d2b3ef9cb515baf0e1280194b6634d7a0ac429?placeholderIfAbsent=true&apiKey=b7b3d02748134357b1767f32084c4983' },
        { title: 'Pharmacy Board of Australia v Tan (Review and Regulation) [2016] VCAT 1653', showInfo: false, expandIcon: 'https://cdn.builder.io/api/v1/image/assets/TEMP/d68e2b5268bce26364730d981671a6b915bd259981251f970911b77e76a26a5b?placeholderIfAbsent=true&apiKey=b7b3d02748134357b1767f32084c4983' },
        { title: 'Pharmacy Board of Australia v Tan (Review and Regulation) [2016] VCAT 1653', showInfo: false, expandIcon: 'https://cdn.builder.io/api/v1/image/assets/TEMP/500bc6b45c8c237ac58ee4a78c6d13ffab27bf757b25adc28aafeac3e0ca49b3?placeholderIfAbsent=true&apiKey=b7b3d02748134357b1767f32084c4983' },
      ]
    };
  },
  methods: {
    hideAllDropdown() { this.$root.$emit('bv::hide::tooltip'); },
    goBack() {
      this.$router.go(-1);
    },
    toggleCaseInfo(index) {
      this.$set(this.precedents, index, {
        ...this.precedents[index],
        showInfo: !this.precedents[index].showInfo,
      });
    },
  }
}
</script>

<style scoped>
.row{
  padding-left: 0px !important;
  --bs-gutter-x:0px !important;
}
.left{
    color: var(--primary);
    font-weight: 500;
    cursor: pointer;
}
.right{
    background: var(--primary);
    color: #fff;
    width: 114px;
    height: 32px;
    padding: 4px 16px;
    gap: 8px;
    border-radius: 6px;
    cursor: pointer;
}
.main-container {
  display: flex;
  justify-content: space-between;
  max-width: 880px;
  width: 100%;
  margin: auto;
}
</style>

<style scoped>
.case-summary-content-full-view {
  border-radius: 8px;
  background: var(--Neutral-White, #fff);
  box-shadow: 0 4px 10px 0 rgba(227, 229, 235, 0.6);
  padding: 0 0 32px 32px;
}@media (max-width: 991px) {
  .case-summary-content-full-view {
    padding-left: 20px;
  }
}

.case-summary-header {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  z-index: 10;
  gap: 20px;
  flex-wrap: wrap;font-family: Poppins, sans-serif;
  color: var(--Neutral-Black, #383a3e);
  border-bottom: 1px solid var(--mid-grey);
}

.case-flow-result {
  padding: 20px 0;
  font-size: 20px;
  font-weight: 600;
  margin: auto 0;
}


.dropdown-modal {
  display: flex;
  flex-direction: column;
  background: var(--Neutral-White, #fff);box-shadow: 0 4px 10px 0 rgba(227, 229, 235, 0.6);
  border: 1px solid #f2f3f3;
  border-radius: 8px;
}

.dropdown-link {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 12px 16px;
}

.checkbox,
.checkbox-2 {
  width: 24px;height: 24px;
  border: 1px solid var(--Neutral-Black, #383a3e);
  border-radius: 4px;
}

.dropdown-link-2 {
  display: flex;
  justify-content: flex-end;
  padding-top: 8px;
  color: var(--Neutral-White, #fff);
}

.button {
  display: flex;
  align-items: center;gap: 4px;
  border-radius: 4px;
  background: var(--Primary-Blue, #0e4485);
  padding: 2px 8px;
}

.img-button {
  width: 18px;
}

.button-title,
.dropdown-text {
  margin: auto 0;
}.icon-decorative {
  width: 20px;
  margin: -19px 0 0 30px;
}

.case-info {
  display: flex;
  flex-direction: column;
  margin-top: -12px;
  max-width: 816px;
  width: 100%;
}

.header {
  font-size: 20px; font-weight: 600;
  color: var(--Neutral-Black, #383a3e);
}

.card {
  border: none !important;
  display: flex;
  margin-top: 24px;
}.topic-card .topic-title,
.abstract-card .abstract-title {
  font-size: 16px;
  font-weight: 500;
  color: var(--Neutral-Black, #383a3e);
  border: none !important;
}

.topic-card .topic-description,
.abstract-card .abstract-description {
  font-size: 14px;
  font-weight: 400;
  color: var(--Neutral-Dark-Grey, #86888d);
  margin-top: 4px;
}.date-court-card {
  border: none !important;
  display: flex;
  flex-direction: column;
  margin-top: 24px;
}

.date-court-content {
  display: flex;
  gap: 20px;
}.decision-date-card, .jurisdiction-card, .court-name-card {
  background: var(--Neutral-Interface-Grey, #fafbfc);
  padding: 18px 38px;
  border-radius: 8px;
}

.decision-day {
  font-size: 25px;
  font-weight: 600;
}

.decision-month-year {
  font-size: 16px;
}.decision-date {
  font-size: 14px;
  font-weight: 400;
  color: var(--Neutral-Dark-Grey, #86888d);
  margin-top: 8px;
}

.jurisdiction-title, .court-name-title {
  font-size: 14px;
  font-weight: 400;
  color: var(--Neutral-Dark-Grey, #86888d);
}
.jurisdiction-location, .court-name-description {
  font-size: 16px;
}

.summary-divider {
  width: 100%;
  margin-top: 24px;
}

.summary {display: flex;
  flex-direction: column;
  margin-top: 23px;
  max-width: 816px;
}

.summary-header {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  font-size: 20px;
  font-weight: 600;
}
.summary-sound{
  display: flex;
  width: 100%;
  align-items: center;
  font-size: 20px;
  font-weight: 600;
}
.summary-title {
  margin: auto 0;
  color: var(--Neutral-Black, #383a3e);
}

.summary-tags {
  display: flex;
  gap: 8px;
  font-size: 14px;
}

.copy-button {
  display: flex;align-items: center;
  gap: 4px;
  padding: 2px 8px;
  border: 1px solid var(--Neutral-Mid-Grey, #d1d2d5);
  background: var(--Neutral-White, #fff);
  border-radius: 4px;
}

.case-summary {
  display: flex;
  flex-direction: column;
  margin-top: 24px;
}.brief-summary-title {
  margin: 0;
  font-size: 16px;
  font-weight: 500;
}

.brief-summary-description {
  font-size: 14px;
  font-weight: 400;
  color: var(--Neutral-Dark-Grey, #86888d);
  margin-top: 12px;
}

.key-points-section {display: flex;
  flex-direction: column;
  margin-top: 24px;
}

.key-points-title {
  font-size: 16px;
  font-weight: 500;
  color: var(--Neutral-Black, #383a3e);
}.key-points-list {
  display: flex;
  flex-direction: column;
  margin-top: 12px;
}

.key-point-item {
  font-size: 14px;
  font-weight: 400;
  color: var(--Neutral-Dark-Grey, #86888d);
  margin-bottom: 12px;
}.key-point-highlight {
  color: var(--Neutral-Black, #383a3e);
}

.expand-button {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 6px;
  border-radius: 4px;
}

.icon-expand {
  width: 24px;
}
#export_box{
  width: 239px !important;
  height: 148px !important;
  left: -85px !important;
}
.DROPDOWN-MENU ::v-deep .tooltip-inner .dropdown-option span {
  margin-right: 0px !important;}
.left-list{
  list-style: none;
  font-size: 16px;
  font-weight: 500;
  line-height: 28px;
  color: var(--dark-grey) !important;
  cursor: pointer;
}
.left-list li.active {
  color: #1890ff;
}
</style>

<style scoped>
.annotations-content-full-view {
  border-radius: 8px;
  background: var(--Neutral-White, #fff);
  box-shadow: 0 4px 10px 0 rgba(227, 229, 235, 0.6);
  display: flex;
  max-width: 880px;
  flex-direction: column;
  font-family: Poppins, sans-serif;
  color: var(--Neutral-Black, #383a3e);
  justify-content: flex-start;
  padding: 32px;
}

.case-flow-result {
  font-size: 20px;
  font-weight: 600;
  line-height: 1.4;
}

.case-image {
  aspect-ratio: 0.98;
  object-fit: contain;
  object-position: center;
  width: 100%;
  margin-top: 32px;
}

.case-copyright {
  align-self: stretch;
  flex: 1;
  margin-top: 32px;
  min-height: 96px;
  width: 100%;
  gap: 10px;
  font-size: 12px;
  font-weight: 400;
  line-height: 24px;
}

@media (max-width: 991px) {
  .annotations-content-full-view {
    padding: 0 20px;
  }

  .case-image,
  .case-copyright {
    max-width: 100%;
  }
}
</style>

<style scoped>
.case-summary-content-full-view {
  border-radius: 8px;
  background-color: var(--Neutral-White, #fff);
  box-shadow: 0 4px 10px 0 rgba(227, 229, 235, 0.6);
  max-width: 880px;
  padding: 32px;
}

.case-flow-result {
  color: var(--Neutral-Black, #383a3e);
}

.precedent-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.precedent-item {
  display: flex;
  align-items: flex-start;
  gap: 16px;
  margin-bottom: 20px;
  margin-top: 20px;
}

.precedent-number {
  border-radius: 50%;
  background-color: var(--Primary-Blue, #0e4485);
  color: var(--Neutral-White, #fff);
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  font: 500 14px/1 Poppins, sans-serif;
}

.precedent-card {
  border-radius: 8px;
  background-color: var(--Neutral-Light-Grey, #f2f3f3);
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 770px;
  padding: 4px 8px;
}

.precedent-content {
  flex: 1;
}

.precedent-title {
  color: var(--Neutral-Black, #383a3e);
  font: 500 16px/28px Poppins, sans-serif;
  margin: 0;
}

.precedent-subtitle {
  color: var(--Neutral-Dark-Grey, #86888d);
  font: 400 14px/24px Poppins, sans-serif;
  margin: 4px 0 0;
}

.expand-button {
  background: none;
  border: none;
  cursor: pointer;
  padding: 6px;
}

.expand-icon {
  width: 24px;
  height: 24px;
}

@media (max-width: 991px) {
  .case-summary-content-full-view {
    padding: 20px;
  }

  .precedent-card {
    width: 100%;
  }
}
</style>

<style scoped>
.case-info-tit {
  display: flex;
  flex-direction: column;
  max-width: 770px;
  padding: 8px 16px;
  background: #fff;
  color: #383a3e;
  border: 1px solid #1890ff;
  border-radius: 8px;
  margin-bottom: 20px;
}
.header {
  font-size: 20px;
  font-weight: 600;
  line-height: 1.4;
  width: 100%;
}

.card, .card-2 {
  margin-top: 16px;
  width: 100%;
  display: flex;
  overflow: hidden;
}
.topic, .abstract {
  font-size: 16px;
  font-weight: 500;
  line-height: 28px;
}

.content {
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  color: #86888d;
  margin-top: 4px;
}.card-2 {
  display: flex;
  align-items: center;
  gap: 16px;
  flex-wrap: wrap;
  font-weight: 500;
  justify-content: flex-start;
}
.decision-date-card, .jurisdiction-card, .court-name-card {
  display: flex;
  flex-direction: column;
  padding: 18px 38px;
  background: #fafbfc;
  border-radius: 8px;
  color: #383a3e;
}

.date-day {
  font-size: 32px;
  font-weight: 600;
}.date-month-year {
  font-size: 16px;
  line-height: 28px;
}

.decision-date, .jurisdiction-label, .court-name-label {
  margin-top: 8px;
  font-size: 14px;
  color: #86888d;
  line-height: 1;
}.jurisdiction, .court-name-card {
  margin-top: 16px;
  padding: 8px 8px 8px 8px;
  min-width: 240px;
  flex: 1;
  flex-basis: 76px;
}

.jurisdiction-value, .court-name-value {
  font-size: 16px;
  color: #383a3e;
}

.DROPDOWN-MENU::v-deep .tooltip-inner {
  max-width: 230px;
  width: 230px;
  margin-left: -30px;
}
</style>